<!-- 赛事中心2-章节选择 -->
<template>
  <div class="chapter_center">
    <div class="content" v-if="!showLoading">
      <!-- 顶部的返回按钮和标题 -->
      <div class="top">
        <div @click.stop="goBack" class="back iconfont cloud-fanhui"></div>
        <div class="name">{{ lessonItem.eventName }}</div>
      </div>

      <!-- 列表内容 -->
      <div class="list">
        <div
          class="item_chapter"
          @click="onItemClickListener(item)"
          :style="{ backgroundColor: item.colours }"
          v-for="(item, index) in chapterData"
          :key="index"
        >
          <!-- 顶部 -->
          <div class="top">
            <!-- 图标 -->
            <img :src="item.eventChapterCover" class="img_chapter" />
          </div>
          <!-- 底部 -->
          <div class="bom">{{ item.eventChapterName }}</div>
        </div>
      </div>
    </div>
    <!-- 加载框 -->
    <Loading v-else></Loading>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      //传递的课程数据
      lessonItem: null,
      //是否是加载框
      showLoading: true,
      //列表数据
      chapterData: []
    };
  },
  computed: {},
  created() {
    //获取传递参数
    this.getParams();
  },
  mounted() {
    this.httpData();
  },
  methods: {
    //获取传递参数
    getParams() {
      this.lessonItem = JSON.parse(sessionStorage.getItem("lessonItem"));
    },

    //返回
    goBack() {
      // this.$router.push({
      //   name: "LessonCenter"
      // });
      this.$router.back(-1);
    },

    //获取列表数据
    async httpData() {
      //跳转的ID
      let eventCenterId = this.lessonItem.eventCenterId;

      let param = this.getHttpParams();

      param.type = 2;

      param.eventCenterId = eventCenterId;

      let res = await this.$http.fetchPost(this.$api.COMPETITION_API, param);

      if (res.data.state == 200) {
        //改变加载状态
        this.showLoading = false;

        //表示请求成功
        this.chapterData = res.data.data;
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //条目点击事件
    onItemClickListener(item) {
      // console.log(`点击条目了:`, item);
      let chapterItem = item;
      chapterItem.eventCenterId = this.lessonItem.eventCenterId;
      sessionStorage.setItem("chapterItem", JSON.stringify(chapterItem));
      this.$router.push({
        name: "CompetitionChooseLesson"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.chapter_center {
  width: $common_width;
  margin: 0 auto;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .top {
      margin-top: 40px;
      height: 55px;
      display: flex;
      align-items: center;
      .back {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 22px;
        font-weight: bold;
        height: 36px;
        width: 36px;
        background: #70cf87;
        border-radius: 50%;
      }

      .name {
        margin-left: 14px;
        padding: 0 10px;
        min-width: 184px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 16px;
        font-weight: bold;
        background: $common_bg;
        border-radius: 22px;
      }
    }

    .list {
      height: calc(100% - 135px);
      margin-top: 50px;
      //网格布局
      display: inline-grid;
      grid-template-columns: 283px 283px 283px 283px;
      grid-template-rows: 353px 353px 353px 353px;
      grid-row-gap: 30px;
      grid-column-gap: 23px;

      .item_chapter {
        border-radius: 10px;
        cursor: pointer;
        transition: transform 0.3s ease; /* 平滑过渡效果 */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .top {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          .img_chapter {
            width: 119px;
            height: 104px;
          }
        }
        .bom {
          height: 68px;
          display: flex;
          justify-content: center;
          font-weight: bold;
          font-size: 26px;
          color: white;
        }
      }

      .item_chapter:hover {
        transform: scale(1.1); /* 放大1.1倍 */
      }
    }
  }
}
</style>
